import incHeader from "../inc-header.vue";
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    incHeader,
    Vcode
  },
  props: {
    mobile: String
  },

  data() {
    return {
      mobileText: "",
      editWindow: false,
      mobileSet: "",
      mobileCode: "",
      sendText: "发送验证码",
      timeOut: 60,
      dialogCode: {
        state: false,
        disabled: true
      },
      snackbar: {
        state: false,
        color: "red",
        text: ""
      },
      vc: {
        show: false,
        width: 460
      },
      timeSet: ""
    };
  },

  mounted() {
    this.usinfo();
    let mobileSet = localStorage.getItem("mobileSet");
    let sendTime = localStorage.getItem("sendTime");

    if (sendTime != null) {
      this.sendTime();
      this.mobileSet = mobileSet;
      this.dialogCode.state = true;
    }
  },

  methods: {
    onSuccess() {
      this.vc.show = false; // 通过验证后，需要手动关闭模态框

      this.sendCode();
    },

    onClose() {
      this.vc.show = false;
    },

    usinfo() {
      let data = {
        module: "user",
        title: "info",
        data: {}
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        that.mobileText = res.data.user.phone;
      });
    },

    sendTime() {
      let timeOut = this.timeOut;
      let that = this;
      let d = new Date();
      let t = d.getTime();
      let sendTime = localStorage.getItem("sendTime");

      if (sendTime != "NaN") {
        sendTime = parseInt(sendTime);
      }

      if (isNaN(sendTime)) {
        let v = t + timeOut * 1000;
        localStorage.setItem("sendTime", v);
        this.sendTime();
      } else if (parseInt(t) > sendTime + 1000) {
        this.sendText = "发送验证码";
        localStorage.removeItem("sendTime");
        this.dialogCode.disabled = false;
        clearTimeout(this.timeSet);
      } else {
        let no = Math.ceil((sendTime - t) / 1000);
        this.sendText = "发送中(" + no + ")...";
        this.timeSet = setTimeout(function () {
          that.sendTime();
        }, 1000);
        return false;
      }
    },

    sendCode() {
      localStorage.setItem("mobile", this.mobileSet);
      let data = {
        module: "usinfo",
        title: "mobilecode",
        data: {
          mobile: this.mobileSet,
          s: 600
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.usinfo && res.data.usinfo.errcode == 0) {
          that.dialogCode = {
            state: true,
            disabled: true
          };
          that.sendTime();
        }

        that.snackbar = {
          state: true,
          color: res.data.usinfo.errcode == 0 ? "green" : "red",
          text: res.data.usinfo.errmsg
        };
      });
    },

    editMobile() {
      this.editWindow = true;
    },

    postMobile() {
      let mobileSet = localStorage.getItem("mobile");
      let data = {
        module: "usinfo",
        title: "mobileset",
        data: {
          mobile: mobileSet,
          mobilecode: this.mobileCode,
          type: "update"
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.usinfo.errcode == 0) {
          localStorage.removeItem("sendTime");
          localStorage.removeItem("mobile");
          clearTimeout(that.timeSet);
          that.usinfo();
          that.dialogCode.state = false;
          that.editWindow = false;
          that.snackbar.state = true;
          that.snackbar.color = "green";
          that.snackbar.text = "修改成功";
        } else {
          that.snackbar.state = true;
          that.snackbar.color = "red";
          that.snackbar.text = res.data.usinfo.errmsg;
        }
      });
    }

  },
  watch: {}
};